<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; text-align:center;"
            class="intro-header">
            <div class="header-text">
                <div style="color: #333333; font-size: 32px; font-weight: bold;" class="header-title">
                    用户协议
                </div>
                <div style="color: #999999;">
                    User agreement
                </div>
            </div>
        </div>
        <div style="width: 1176px; margin: 0 auto; display: flex; justify-content: center;">


            <div>
                <div style="font-size: 24px; color:#3D3D3D; margin: 40px 0px 60px 0px;">
                    用户机密
                </div>
                <div>
                    <p><strong>欢迎使用科研诚信管理系统（以下简称&ldquo;科研诚信&rdquo;）！</strong></p>
                    <ul>
                        <li>提示：在使用服务之前，您应当认真阅读并遵守《科颐轩服务协议》（以下简称&ldquo;本协议&rdquo;）。</li>
                        <li>当您按照注册页面提示填写信息、点击&ldquo;我已经阅读并同意遵守《科颐轩服务协议》&rdquo;按钮且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得客服对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
                        </li>
                    </ul>
                    <p><strong>一、缔约主体</strong></p>
                    <ul>
                        <li>本协议由通过科颐轩网站、移动客户端软件及其他方式使用科研诚信平台服务的用户（以下简称&ldquo;用户&rdquo;或&ldquo;您&rdquo;）与广州市科颐轩电子科技有限公司(（以下简称&ldquo;科颐轩&rdquo;或&ldquo;我们&rdquo;）共同缔结。
                        </li>
                    </ul>
                    <p><strong>二、协议的变更和效力</strong></p>
                    <ol>
                        <li>本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称&ldquo;规则&rdquo;）。前述规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
                        </li>
                        <li>我们有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则一经公布，立即取代原协议及规则并自动生效。如您不同意相关变更，应当立即停止使用科研诚信平台服务，如您继续使用科研诚信平台服务或进行任何网站活动，即表示您已接受经修订的协议和规则。
                        </li>
                    </ol>
                    <p><strong>三、注册及账号管理</strong></p>
                    <ol>
                        <li>您应该确认，注册账号主体是具有法定的相应权利能力和行为能力的自然人、法人或其他组织，能够独立承担法律责任。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且我们有权注销（永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家公司或其他法律主体进行注册或以其他我们允许的方式实际使用本服务，则您声明和保证，您有权使该公司或该法律主体受本协议&ldquo;条款&ldquo;的约束。
                        </li>
                        <li>您注册时应诚信向本站提供真实、准确、完整和反映当前情况的身份及其他相关信息，为保证我们提供的服务质量，您应保证其提供的注册资料真实、准确、完整、合法有效。你理解并同意，您承诺注册的账号名称、头像和简介等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得使用可能侵犯他人权益的用户名（包括但不限于涉嫌商标权、名誉权侵权等），否则我们有权不予注册或停止服务并收回注销账号，因此产生的损失由您自行承担。
                        </li>
                        <li>在您成功注册后，您可以根据本站规定改变您的密码。我们将根据账号和密码确认您的身份。您应妥善保管您的账户和密码，并对利用该账户和密码所进行的一切活动负全部责任。</li>
                        <li>您如果发现任何非法使用等可能危及您的账户安全的情况时，您应当立即以有效方式通知科颐轩，要求我们暂停相关服务或冻结账户，并向公安机关报案。您理解并确认，我们不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得我们的同意，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
                        </li>
                        <li>您了解并同意，如您注册账号后连续超过12个月未登录，我们为网站优化管理之目的有权回收该账号，相关问题及责任均由您自行承担。</li>
                        <li>我们根据本协议收回或取消账号后，有权自行对账号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任；如果账号为管理员账号，我们有权取消和删除该管理员账号以及通信录成员基于该企业的一切信息和相关权益，包括但不限于解除与企业之间的服务关系、解散企业通信录、取消通信录成员与该企业相关的权益、删除管理员账号中的所有信息、删除企业通信录成员基于企业的所有信息等。
                        </li>
                    </ol>
                    <p><strong>四、服务使用规范</strong></p>
                    <ol>
                        <li>用户充分了解并同意，我们仅为用户提供服务平台，您应自行对利用服务从事的所有行为及结果承担责任。相应地，您应了解，使用科研诚信平台服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。
                        </li>
                        <li>您在使用科研诚信平台服务过程中，您承诺不会利用本服务进行任何违法或不当的活动：</li>
                    </ol>
                    <p>2.1 不得传播或发表损害国家、社会公共利益和涉及国家安全的信息资料或言论；</p>
                    <p>2.2 不得有违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等&ldquo;七条底线&rdquo;要求的行为；</p>
                    <p>2.3 不利用本站从事窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
                    <p>2.4 不发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；</p>
                    <p>2.5 不得进行涉嫌侵害他人权利的行为。</p>
                    <p>2.6 不得将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送。</p>
                    <ol>
                        <li>您承诺不以任何形式使用本服务侵犯科颐轩的商业利益，或从事任何可能对我们造成损害的行为。</li>
                        <li>您了解并同意，在我们提供服务过程中，科颐轩及其关联公司有权以各种方式投放各种商业性广告或其他任何类型的推广信息，同时，您同意接受以电子邮件或其他方式向您发送的上述广告或推广信息。
                        </li>
                        <li>如您申请科研诚信平台管理账号，则应自行通过账号添加和管理成员，并对管理成员的行为自行承担责任，我们根据您服务管理账号的行为对成员服务的调整亦由您承担后果，因此给他人造成损失的，您应当予以赔偿。
                        </li>
                        <li>如果科颐轩发现您有违反本协议约定的，科颐轩有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于收回账号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。</li>
                    </ol>
                    <p><strong>五、许可使用费用</strong></p>
                    <ol>
                        <li>本服务的任何免费试用或免费功能和服务不应视为科颐轩放弃后续收费的权利。我们保留随时通知您并收取费用的权利。</li>
                        <li>所有费用需通过我们接受的支付方式事先支付。前述使用费不包含其它任何税款、费用或相关汇款等支出，否则您应补足付款或自行支付该费用。</li>
                        <li>我们有权根据实际情况提前10天单方调整费用标准及收费方式，并以公告形式通知您，而无需获得您的事先同意，如您不同意收费应当立即停止服务的使用，否则使用则视为您已同意并应当缴纳费用。
                        </li>
                    </ol>
                    <p><strong>六、服务终止</strong></p>
                    <ol>
                        <li>您同意，鉴于互联网服务的特殊性，科颐轩有权随时中止、终止向您提供部分或全部科研诚信平台服务；对于免费服务之中止或终止，我们无需向您发出通知。</li>
                        <li>出现以下情况时，我们有权终止服务，并要求用户赔偿损失：</li>
                    </ol>
                    <p>2.1 用户违反第三条注册义务的；</p>
                    <p>2.2 用户使用收费服务时未按规定支付相应服务费；</p>
                    <p>2.3 用户违反第四条服务使用规范之规定。</p>
                    <p><strong>七、用户隐私条款</strong></p>
                    <ol>
                        <li>科研诚信平台服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，请您妥善保管账户及密码信息。</li>
                        <li>我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在服务器中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。科颐轩会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，科颐轩将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，
                        </li>
                        <li>我们对您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败不承担责任。</li>
                        <li>科研诚信平台在注册时收集的资料的目的及用途如下:向您提供我们的各项服务；辨认以及确认您的身份；我们的客服人员有需要时可以与您联系；为改进我们的服务及网站内容而进行市场研究调查；为我们搞的活动、市场销售和推广计划收集资料；遵守法律、政府和监管机关的规定，包括但不限于对个人资料披露及通知的规定；您提供给我们的个人资料及不具名资料，只保留到搜集的目的已达到的时候，除非应适用的法律法规之规定而继续保留。
                            我们承诺用户的个人资料不会出租或出售给任何无关的第三方。</li>
                        <li>科研诚信平台保留使用汇总统计性信息的权利，这些信息应当是匿名，且不是针对特定用户的。</li>
                    </ol>
                    <p><strong>八、知识产权</strong></p>
                    <ol>
                        <li>本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归我们所有。
                        </li>
                        <li>用户在平台创建的独创性数据归属客户所有，客户有权进行任何形式的处置，包括从平台中复制，导出和删除。</li>
                    </ol>
                    <p><strong>九、免责及责任的限制与排除</strong></p>
                    <ol>
                        <li>本软件经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证本软件完全没有错误。对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。如果出现不兼容及软件错误的情况，您可通过科颐轩官网的&ldquo;联系我们&rdquo;中获得联系方式，获得技术支持。
                        </li>
                        <li>使用本软件产品风险由您自行承担，在适用法律允许的最大范围内，对因使用或不能使用本软件所产生的损害及风险，包括但不限于直接或间接的个人损害、商业赢利的丧失、贸易中断、商业信息的丢失或任何其它经济损失，我们不承担任何责任。
                        </li>
                        <li>对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务，我们不承担任何责任。
                        </li>
                    </ol>
                    <p><strong>十、有效通知</strong></p>
                    <ol>
                        <li>我们向您发出的任何通知，可采用电子邮件、页面公开区域公告、个人网络区域提示、手机短信或常规信件等方式，且该等通知应自发送之日视为已向用户送达或生效。</li>
                        <li>您同意，您向科颐轩发出的任何通知应发至科研诚信平台对外正式公布告知用户的电子邮件、通信地址、传真号码等联系信息，或使用其他我们认可的其他通知方式进行送达。</li>
                    </ol>
                    <p><strong>十一、违约及争议解决</strong></p>
                    <ol>
                        <li>本协议解释、适用，以及与本协议有关争议，均应依照中华人民共和国法律予以处理。</li>
                        <li>我们有权根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BGImage from '@/assets/ball-background.png';
export default {
    data() {
        return {
            BGImage
        }
    }
}
</script>

<style lang="less" scoped>
.intro-header {
    width: 100%;
    height: 250px;
    width: 100%;
    background: url(../../../assets/ball-background.png);
}
</style>